import React, { FunctionComponent } from 'react'
import Socials, {SocialLinkProps} from './Socials'

import './Footer.css'
import './Socials.css'

interface FooterProps {
    musicLinks : Array<SocialLinkProps>,
}


const Footer : FunctionComponent<FooterProps> = ( { musicLinks } ) => (
    <div className='footer'>
        {/* <span className='footer-text'>Fournine</span> */}
        <Socials links={musicLinks} />
    </div>
)

export default Footer