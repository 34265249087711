import React, { ReactNode, FunctionComponent } from 'react'
import './Container.css'

type Props = {
    children?: ReactNode,
    type?: string,
    large?: boolean
}

const LargeContainer : FunctionComponent<Props> = ( { children, type = '', large = false } ) => (
    <div className={`container ${type} ${large ? 'large' : ''}`}>{children}</div>
)

export const HorizontalContainer : FunctionComponent<Props> = ( { children, type = '', large = false } ) => (
    <div className={`container-horizontal ${type} ${large ? 'large' : ''}`}>{children}</div>
)

export default LargeContainer