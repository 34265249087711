import React, { FunctionComponent, useState } from 'react'
import Container from '../components/Container'
import Video from '../components/Video'

type ShowAllProps = {
    show: boolean,
    onClick: Function,
}

const ShowAll : FunctionComponent<ShowAllProps> = ({show, onClick}) => (
    <div className="show-all-videos" onClick={() => onClick(!show)}>
        <span>{show ? "Show Less" : "Show All"}</span>
    </div>
)

const Videos : FunctionComponent = () => {
    const [ show, setShow ] = useState(false)
    const minVideos = 6

    const videos = [
        "https://www.youtube.com/embed/xg9cZG3LZhI",
        "https://www.youtube.com/embed/PAH9hZg_0kY",
        "https://www.youtube.com/embed/ox432BthPws",
        "https://www.youtube.com/embed/xf0O_tDi_3I",
        "https://www.youtube.com/embed/scO-mWPWT74",
        "https://www.youtube.com/embed/kx-LnKGSZcA",
        "https://www.youtube.com/embed/1r9R1FuOkO4",
        "https://www.youtube.com/embed/KXxA6W6oJho",
        "https://www.youtube.com/embed/Ag2cbxGDzgc",
        "https://www.youtube.com/embed/YqDpPgjlYhw",
        "https://www.youtube.com/embed/FEvuCb81DRs",
        "https://www.youtube.com/embed/8WrXrPmcw_o",
        "https://www.youtube.com/embed/WrzPmzcAuO0",
        "https://www.youtube.com/embed/1iF5B_Ilg98",
        "https://www.youtube.com/embed/90zdbrOS03E",
        "https://www.youtube.com/embed/7bTYmrhZiAE",
        "https://www.youtube.com/embed/E5ZyVFhBj_U",
        "https://www.youtube.com/embed/AJc22k1b720",
        "https://www.youtube.com/embed/QSSB2nMemro",
        "https://www.youtube.com/embed/Ytm777Fk2Ug",
    ]

    const displayedVideos = show ? videos : videos.slice(0,minVideos)
    return (
        <>
        <Container type={"videos"} large>
            {displayedVideos.map((videoUrl) => <Video url={videoUrl} />)}
        </Container>
        {videos.length > minVideos && <ShowAll onClick={setShow} show={show} />}
        </>

    )
}
export default Videos