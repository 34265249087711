import React, { FunctionComponent } from 'react'
import { NavLink,  RouteComponentProps, withRouter } from 'react-router-dom'
import Socials, {SocialLinkProps} from './Socials'

import './Header.css'


export type HeaderLinkProps = {
    label : string,
    url: string,
    
}

interface HeaderProps extends RouteComponentProps {
    headerLinks : Array<HeaderLinkProps>,
    socialLinks : Array<SocialLinkProps>,
}

// const BasketLink : FunctionComponent = () => (
//     <NavLink to='/basket' exact className='header-link' activeClassName='header-link-active'> <FontAwesomeIcon icon={faShoppingBasket} /></NavLink>
// )

const Header : FunctionComponent<HeaderProps> = ( { match, headerLinks, socialLinks } ) => (
    <div className='header'>
            <span className='header-text'>Fournine</span>
            {headerLinks.map( ( { label, url } ) => <NavLink key={label} to={`/${url}`} exact className='header-link' activeClassName='header-link-active'>{label}</NavLink> ) }
        <Socials links={socialLinks} />
    </div>
)

export default withRouter(Header)