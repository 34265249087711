import React, { FunctionComponent } from 'react'
import './Video.css'

export type VideoProps = {
    url: string
}

const Video : FunctionComponent<VideoProps> = ( { url } ) => (
    <div className='video' key={url}>
        <iframe className='video' key={url} title={url} src={url} frameBorder="0" allowFullScreen></iframe>
    </div>
)

export default Video