import React, { FC, ComponentType } from 'react'
import { BrowserRouter as Router, Route, RouteProps } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faInstagram,
  faFacebook,
  faTwitter ,
  faSpotify,
  faSoundcloud,
} from '@fortawesome/free-brands-svg-icons'


import Header, { HeaderLinkProps } from './components/Header'
import Footer from './components/Footer'
import {SocialLinkProps} from './components/Socials'

import Home from './pages/Home'
import Events from './pages/Events'
import Videos from './pages/Videos'

import './App.css'

const App : FC = () => {

  interface AnimatedRouteProps extends RouteProps {
    component : ComponentType<any>
  }

  const mainRoutes : Array<AnimatedRouteProps> = [
    {path: '/', component: Videos},
    {path: '/videos', component: Videos},
  ]

  // const subRoutes : Array<AnimatedRouteProps> = [
  //   {path: '/shop/:shopId', component: ItemPage},
  // ]

  const headerLinks : Array<HeaderLinkProps> = []
  // [
  //   { label: 'Home', url: '' },
  //   { label: 'Videos', url: 'videos' },
  //   // { label: 'About', url: 'contact' },
  // ]
  

  // const socialLinks : Array<SocialLinkProps> = [
  //   { label: 'Instagram', url: 'https://www.instagram.com/fournine40/?hl=en', icon: <FontAwesomeIcon icon={faInstagram} /> },
  //   { label: 'Facebook', url: 'https://www.facebook.com/SammFourNine', icon: <FontAwesomeIcon icon={faFacebook}  />  },
  //   { label: 'Twitter', url: 'https://twitter.com/Fournine40', icon: <FontAwesomeIcon icon={faTwitter}  />  },
  //   // { label: 'Youtube', url: 'https://youtube.com', icon: <FontAwesomeIcon icon={faYoutube}  />  },
  //   // { label: 'Spotify', url: 'https://spotify.com', icon: <FontAwesomeIcon icon={faFacebook}  />  },
  //   // { label: 'SoundCloud', url: 'https://soundcloud.com', icon: <FontAwesomeIcon icon={faYoutube}  />  },
    
  // ]

  const musicLinks : Array<SocialLinkProps> = [
    { label: 'Instagram', url: 'https://www.instagram.com/fournine40/?hl=en', icon: <FontAwesomeIcon icon={faInstagram} /> },
    { label: 'Facebook', url: 'https://www.facebook.com/SammFourNine', icon: <FontAwesomeIcon icon={faFacebook}  />  },
    { label: 'Twitter', url: 'https://twitter.com/Fournine40', icon: <FontAwesomeIcon icon={faTwitter}  />  },
    // { label: 'Youtube', url: 'https://youtube.com', icon: <FontAwesomeIcon icon={faYoutube}  />  },
    { label: 'Spotify', url: 'https://open.spotify.com/artist/4t5tP3pvrx50ONnbos94lz?si=-Kn8rKeASASPLx7RQ_98Dg', icon: <FontAwesomeIcon icon={faSpotify}  />  },
    { label: 'SoundCloud', url: 'https://soundcloud.com/fournine40', icon: <FontAwesomeIcon icon={faSoundcloud}  />  },
  ]

  return (
    <div className='background'>
      <div className="App">
        <Router>
          <header>
            <Header headerLinks={headerLinks} socialLinks={[]} />
          </header>
          {mainRoutes.map( ( { path, component: Component } : AnimatedRouteProps ) => (
              <Route key={path as string} path={path} exact>
                { ({match}) => (
                  <CSSTransition in={!!match} key={path as string} timeout={{enter: 1000, exit: 0}} classNames='page' unmountOnExit>
                    <div className="page">
                      <Component />
                    </div>
                  </CSSTransition>
                )}
              </Route> 
          ) )}
          <footer>
            <Footer musicLinks={musicLinks} />
          </footer>
        </Router>
      </div>
    </div>
  )
}

export default App
